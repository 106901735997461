export const palette = {
    red: '#ff405d',
    orange: '#ff7400',
    yellow: '#FFD700',
    blue: '#5373fc',
    black: '#000035',
    white: '#ffffff',
    ultraPale: 'rgb(249, 249, 249)',
    lightBS: 'rgba(0, 0, 0, 0.08) 0px 3px 10px',
    heavyBS: 'rgba(0, 0, 0, 0.63) 0px 0px 43px -13px'
}