import React from 'react'
import {Switch, Route, BrowserRouter, Redirect, Router} from "react-router-dom";
import ProtectedRoute from "./PrivateRoutes";
import Auth from "../pages/auth";
import history from "./history";

const Main = (props) => {

    return (
        <Router history={history}>
            <Switch>
                {/*<ProtectedRoute path='/second-step' component={Jobs}/>*/}
                <Route path="/" component={Auth}/>
                <Redirect to="/"/>
            </Switch>
        </Router>
    )
}

export default Main;
