import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const myOtherApp = 'lapiezaproduction';

const firebaseConfig = {
    apiKey: "AIzaSyBmvJBuszh7iynfIQ8ldja7AVj3EB-91DY",
    authDomain: "adopt-a-cto.firebaseapp.com",
    databaseURL: "https://adopt-a-cto.firebaseio.com",
    projectId: "adopt-a-cto",
    storageBucket: "adopt-a-cto.appspot.com",
    messagingSenderId: "710800290342",
    appId: "1:710800290342:web:9d68b9c031276d03f9ece7",
    measurementId: "G-7HGJ0RK4CQ"
};

const piezaproduction = {
    apiKey: "AIzaSyAJk36pMtxI9JlyZCmrBZU1NaCDIGEfsmo",
    authDomain: "lapieza-production.firebaseapp.com",
    databaseURL: "https://lapieza-production.firebaseio.com",
    projectId: "lapieza-production",
    storageBucket: "lapieza-production.appspot.com",
    messagingSenderId: "781662415856",
    appId: "1:781662415856:web:51f8695fe9ca0a0f"
};

firebase.initializeApp(piezaproduction);
firebase.initializeApp(firebaseConfig, myOtherApp);
const second__database = firebase.app(myOtherApp).firestore();
const database = firebase.firestore();
var storage = firebase.storage();
var auth = firebase.auth();

export { auth, firebase, storage, second__database, database as default};
