import React, {useState} from 'react';
import Logo from "../../images/logo.png";
import styled from 'styled-components';
import {palette} from "../../style/pallette";
import leftImg from "../../images/image.jpg";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse'
import Error from '../../components/Creator/Modal/ErrorRegister';
import database, {second__database, auth} from '../../firebase/firebase.js'
import axios from 'axios';
import {baseURL, destino} from '../../system'
import moment from "moment";
import Select from 'react-select'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,.5) !important'
    },
    text: {
        color: '#fff',
        textAlign: 'center',
        fontSize: '1.5rem'
    },
    paper: {
        margin: theme.spacing(1),
    },
}));

const Creator = (props) => {

    const experiencia = [
        'Front-End Developer',
        'Mobile Developer',
        'Full-Stack Developer',
        'UX/UI Designer',
        'Devops Engineer',
        'Tech Support',
        'QA Engineer',
        'Data Science'
    ]

    const mainlenguajes = [
        'Javascript', 'Java', 'Python', 'Ruby', 'C/C++', 'C#', '.net', 'PHP', 'Objective-C', 'Swift', 'Kotlin', 'Ninguno', 'Otros'
    ]

    const secondlenguajes = [
        'Javascript', 'Java', 'Python', 'Ruby', 'C/C++', 'C#', '.net', 'PHP', 'Objective-C', 'Swift', 'Kotlin', 'Ninguno', 'Otros'
    ]

    const typeVacancy = [
        'Trainee', 'Junior', 'Mid', 'Senior'
    ]

    const share = [
        'Si', 'No'
    ]

    const typeSchool = [
        'Facebook Developer Circle',
        'Platzi',
        'Laboratoria',
        'Le Wagon',
        'Hola Code',
        'Kodemia',
        "Actio",
        "Aiep",
        "Areandina",
        "Ayuntamiento De Tehuacan",
        "BEDU",
        "Bootcamp Institute",
        "Capitulo 630 Iise",
        "Capítulo Estudiantil 630 Iise",
        "Cedhinfo",
        "Centro De Estudios Intensivos Cholula",
        "Centro Universitario De La Cienega",
        "Corporación Unificada Nacional  En Educación Superior Cun",
        "Corporación Universitaria Autónoma De Nariño",
        "Corporaciòn Universitaria De Ciencia Y Desarrollo Uniciencia",
        "Corporación Universitaria Minuto De Dios",
        "Corporación Universitaria Minuto De Dios",
        "Corporación Universitaria Rafael Nuñez",
        "Dasc Instituto Tecnológico Universitario",
        "ESCOM",
        "Facultad De Estudios Superiores Acatlán, Unam",
        "Facultad De Ingeniería",
        "Fundación Rafael Dondé",
        "Fundación Universitaria Del Área Andina",
        "Fundación Universitaria Los Libertadores",
        "Grid Professional",
        "Ieca Instituto Estatal De Capacitación",
        "Iesa Instituto De Estudios Superiores De Administración",
        "Imef Universitario",
        "Instinto Tecnológico De Chetumal",
        "Instituto De Ciencias Tecnológicas.",
        "Instituto Joaquin Camaño",
        "Instituto Profesional Aiep",
        "Instituto Tecnológico De Gustavo A. Madero",
        "Instituto Tecnológico Del Valle De Oaxaca",
        "Instituto Tecnológico Superior De Cananea",
        "Ironhack",
        "ITAM",
        "ITESM",
        "Oicdh",
        "Tafer",
        "Tecnológico De Estudios Superiores De Chalco",
        "Tecnológico Universitario Playacar",
        "Tfg",
        "The Bridge",
        "Uaem",
        "Uagro",
        "UAM",
        "UNAM",
        "Uniminuto",
        "Unitec",
        "Universidad Alianza Hispana",
        "Universidad Anáhuac",
        "Universidad Autónoma De Colombia",
        "Universidad Autónoma De Guerrero",
        "Universidad Autónoma Del Estado De Hidalgo",
        "Universidad De Antioquia",
        "Universidad De Antioquia",
        "Universidad De Belgrano",
        "Universidad De La República Mexicana S.C",
        "Universidad De La Salle",
        "Universidad De Los Andes",
        "Universidad De Manizales",
        "Universidad Del Caribe",
        "Universidad Del Salvador",
        "Universidad Del Salvador Pilar",
        "Universidad Del Valle De Mèxico,",
        "Universidad Interamericana",
        "Universidad Latina De América",
        "Universidad Libre",
        "Universidad México Americana Del Golfo",
        "Universidad Nacional De Mar Del Plata",
        "Universidad Nacional Mayor De San Marcos",
        "Universidad Panamericana",
        "Universidad Politécnica De Chiapas",
        "Universidad Politécnica De Juventino Rosas",
        "Universidad Politécnica Metropolitana De Puebla",
        "Universidad Salvador Boliva",
        "Universidad Técnica Federico Santa María",
        "Universidad Tecnología De Rodeo",
        "Universidad Tecnológica De Emiliano Zapata",
        "Universidad Tecnológica De Huejotzingo",
        "Universidad Tecnológica De Nezahualcóyotl",
        "Universidad Tecnológica De San Juan Del Río",
        "Universidad Tecnológica De San Juan Del Río Queretaro",
        "Universidad Tecnológica De San Miguel De Allende",
        "Universidad Tecnológica De Santa Catarina",
        "Universidad Tecnológica De Tula-Tepeji",
        "Universidad Tecnológica Emiliano Zapata",
        "Universidad Tecnológica Emiliano Zapata Del Estado De Morelos",
        "Universidad Tecnológica Metropolitana",
        "Usal",
        "Ut Nogales Sonora",
        "Uvm Hispano",
        "Women Who Code",
        "Z-Tech Middle Americas",
        'Otra'

    ]

    const [state, setState] = useState({
        email: '',
        password: '',
        type: 'full',
        errors: null,
        terminos: false,
        register: false,
        name: '',
        estado: '',
        open: false,
        goLogin: false,
        error: false,
        message: '',
        phone: '',
        names: '',
        lastNames: '',
        phoneNumber: '',
        vacante: '',
        fileName: "",
        first: true,
        second: false,
        goFeria: false,
        linkedIn: '',
        experiencia: [],
        mainlenguajes: [],
        secondlenguajes: [],
        typeVacancy: [],
        share: true,
        typeSchool: []
    })

    const classes = useStyles();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setState(prevState => ({...prevState, register: true, estado: 'Comenzando la migración', open: true}))
        const exist = (await second__database.collection("profile").doc("data").collection("users").where("email", "==", state.email).get()).docs.length
        if (exist) {
            setState(prevState => ({
                ...prevState,
                message: 'Ya te has registrado a la feria',
                register: false,
                error: true,
                goFeria: true
            }))
            setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
            return;
        } else {
            let login = null;
            try {
                await auth.signInWithEmailAndPassword(state.email, state.password);
                console.log('Sesion iniciada')
            } catch (err) {
                setState(prevState => ({
                    ...prevState,
                    register: false,
                    error: true,
                    message: err.message,
                    goFeria: false
                }))
                setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
                return;
            }
            const sendable = {
                email: state.email
            }
            const user = (await axios.request({
                baseURL,
                url: 'exist',
                data: sendable,
                method: 'POST'
            })).data
            if (!user.success) {
                setState(prevState => ({
                    ...prevState,
                    register: false,
                    error: true,
                    message: 'No tienes cuenta en LaPieza.io',
                    goFeria: false
                }))
                setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
                return;
            }
            const claims = (await axios.request({
                baseURL,
                url: 'claims',
                data: sendable,
                method: 'POST'
            })).data
            if (claims.success) {
                if (claims.info.customClaims?.company) setState(prevState => ({
                    ...prevState,
                    register: false,
                    error: true,
                    message: 'Tú cuenta es tipo empresa y no puede ser importada a la feria',
                    goFeria: false
                }))
                setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
                return;
            }
            setState(prevState => ({
                ...prevState,
                first: true,
                second: false,
                goFeria: false
            }))
            setState(prevState => ({
                ...prevState,
                first: true,
                second: false
            }))
            handleSubmitsecond()
        }
        setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
    };

    const handleSubmitsecond = async () => {
        // event.preventDefault();
        setState(prevState => ({...prevState, register: true, estado: 'Comenzando la migración', open: true}))
        const {
            experiencia,
            mainlenguajes = [],
            typeVacancy,
            share,
            linkedIn = '',
            secondlenguajes = [],
            typeSchool
        } = state;
        if (
            // experiencia.length &&
            // // mainlenguajes.length &&
            // typeVacancy.length &&
            share === true || share === false) {
            const exist = (await second__database.collection("profile").doc("data").collection("users").where("email", "==", state.email).get()).docs.length
            if (exist) {
                setState(prevState => ({
                    ...prevState,
                    message: 'Ya te has registrado a la feria',
                    register: false,
                    error: true,
                    goFeria: true
                }))
                setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
                return;
            }
            const sendable = {
                email: state.email
            }
            const user = (await axios.request({
                baseURL,
                url: 'exist',
                data: sendable,
                method: 'POST'
            })).data
            const data = (await database.collection("profile").doc("data").collection("users").where("email", "==", state.email).get()).docs[0].data();
            const payload = {
                id: user.id,
                UpdateCV: true,
                alternativeEmail: data?.additional_email || "",
                alternativePhoneNumber: data?.additional_phone_number || "",
                authTime: moment().valueOf(),
                birthdateDay: data?.birthdate.day,
                birthdateMonth: data?.birthdate.month,
                birthdateYear: data?.birthdate.year,
                birthdate: data?.birthdateDay,
                city: data.city || "",
                courses: data?.courseDates
                    ? [
                        ...data.courseDates.map(e => {
                            return {
                                courseName: e.courseName,
                                description: e.description,
                                courseLink: e.link
                            };
                        })
                    ]
                    : [],
                education: data?.educationDates
                    ? [
                        ...data?.educationDates.map(e => {
                            return {
                                career: e.collegeCareer,
                                studyingNow: e.currentlyStudying,
                                endDateYear: e.eduDepartureYear,
                                startDateYear: e.eduEntryDateYear,
                                institutionName: e.educationInstitution,
                                levelEducation: e.levelEducation
                            };
                        })
                    ]
                    : [],
                email: data?.email || "",
                userJobs: data?.experienceDates || [],
                gender: data?.gender || "",
                languages: data?.languagesDates
                    ? data.languagesDates.map(e => {
                        return {
                            language: e.language || "",
                            languageLevel: e.level || ""
                        };
                    })
                    : [],
                lastNames: data.lastNames || "",
                names: data.names || "",
                links: data?.otherLinks
                    ? data.otherLinks.map(e => {
                        return {
                            linkName: e.linkName,
                            linkUrl: e.otherlink
                        };
                    })
                    : [],
                phoneNumber: data.phoneNumber || "",
                postalCode: data.postal_code || "",
                state: data.region || "",
                relocation: true,
                contBenefits: data?.searchDates?.benefits,
                contEducation: data?.searchDates?.education,
                contIncrease: data?.searchDates?.increase,
                contLeadteams: data?.searchDates?.lead_teams,
                Lifebalance: data?.searchDates?.life_balance,
                Newfamily: data?.searchDates?.new_family,
                selectedSkills: data.skillDates || [],
                userValues: data.valuesStudyDates || [],
                experiencia,
                mainlenguajes,
                typeVacancy,
                share,
                linkedIn,
                secondlenguajes,
                typeSchool,
                whatFair: 'RYT',
            };
            const register = (await axios.request({
                baseURL,
                url: 'register',
                data: {payload},
                method: 'POST'
            })).data
            try {
                auth.signOut()
                console.log('Sesion Cerrada')
                window.location.href = destino + '/login';
            } catch (err) {
                console.error('Error al cerrar sesión')
            }
        } else {
            setState(prevState => ({
                ...prevState,
                message: 'Rellena todos los campos con *',
                register: false,
                error: true,
                goFeria: false
            }))
            setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
            return;
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState({...state, [name]: value});
    };

    const handleSelectChange = (e, label) => {
        let temp = '';
        if (e) {
            const name = e.name;
            const value = e.value;
            if (!name) {
                setState({...state, [label]: e});
            } else {
                setState({...state, [name]: value});
            }
        } else {
            switch (label) {
                case 'experiencia':
                    temp = []
                    break
                case 'mainlenguajes':
                    temp = []
                    break
                case 'secondlenguajes':
                    temp = []
                    break
                case 'typeVacancy':
                    temp = []
                    break
                case 'share':
                    temp = ''
                    break
                case 'typeSchool':
                    temp = []
                    break
                default:
                    temp = ''
                    break
            }
            setState({...state, [label]: ''});
        }
    };

    const {
        className
    } = props;

    const resetError = () => setState(prevState => ({...prevState, error: !prevState.error}))

    return (
        <div className={className}>
            <Backdrop className={classes.backdrop} open={state.open}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Error
                closed={resetError}
                open={state.error}
                message={state.message}
                goLogin={state.goLogin}
                goFeria={state.goFeria}
            />
            <aside className='creator-left' style={{backgroundImage: `url(${leftImg})`}}/>
            <div className="creator-right">
                {/*Primera caja*/}
                <Collapse in={state.first}>
                    <Paper elevation={0} className={classes.paper}>
                        <img src={Logo} alt=""/>
                        <h3>Registro con tu cuenta de LaPieza.io</h3>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="email">Correo electrónico *</label>
                            <input
                                type="email"
                                name="email"
                                value={state.email}
                                required
                                onChange={handleInputChange}
                            />
                            <label htmlFor="email">Contraseña *</label>
                            <input
                                name="password"
                                type={state.showPassword ? 'text' : 'password'}
                                value={state.password}
                                required
                                onChange={handleInputChange}
                            />
                            <div className="creator-right_terminos">
                                <input type="checkbox" required value={state.terminos}
                                       onChange={(e) => setState({...state, terminos: e.target.checked})}/>
                                <a href="https://lapieza.io/legal/terminos-y-condiciones" target="_blank">Aceptar
                                    términos y
                                    condiciones</a>
                            </div>
                            <button>{state.register ? state.estado : 'Importar cuenta'}</button>
                        </form>
                    </Paper>
                </Collapse>
                {/*Segunda caja*/}
                <Collapse in={state.second}>
                    <Paper elevation={0} className={classes.paper}>
                        <img src={Logo} alt=""/>
                        <h3>Un último paso</h3>
                        <form onSubmit={handleSubmitsecond}>
                            {/*<label htmlFor="email">Link de LinkedIn</label>*/}
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    name="linkedIn"*/}
                            {/*    placeholder="https://www.linkedin.com/company/lapieza/"*/}
                            {/*    value={state.linkedIn}*/}
                            {/*    onChange={handleInputChange}*/}
                            {/*/>*/}
                            <label htmlFor="email">Área de experiencia *</label>
                            <Select
                                name="experiencia"
                                id="experiencia"
                                isMulti
                                onChange={(e) => handleSelectChange(e, "experiencia")}
                                placeholder="Experiencia en..."
                                styles={{
                                    container: (provided, state) => ({
                                        ...provided,
                                        zIndex: 1006
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        fontSize: '14px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        borderBottom: '1px dotted pink',
                                        color: state.isSelected ? '#FFF' : palette.blue,
                                        padding: 10,
                                        fontSize: '14px'
                                    }),
                                }}
                                options={[...experiencia.map(job => ({
                                    value: job,
                                    label: job,
                                    name: "experiencia"
                                }))]}
                            />
                            {/*<label htmlFor="email">Lenguajes de programación principales que manejas *</label>*/}
                            {/*<Select*/}
                            {/*    name="mainlenguajes"*/}
                            {/*    id="mainlenguajes"*/}
                            {/*    isMulti*/}
                            {/*    onChange={(e) => handleSelectChange(e, "mainlenguajes")}*/}
                            {/*    placeholder="Javascript, C++..."*/}
                            {/*    styles={{*/}
                            {/*        container: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            zIndex: 1005*/}
                            {/*        }),*/}
                            {/*        valueContainer: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            fontSize: '14px'*/}
                            {/*        }),*/}
                            {/*        option: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            borderBottom: '1px dotted pink',*/}
                            {/*            color: state.isSelected ? '#FFF' : palette.blue,*/}
                            {/*            padding: 10,*/}
                            {/*            fontSize: '14px'*/}
                            {/*        }),*/}
                            {/*    }}*/}
                            {/*    options={[...mainlenguajes.map(job => ({*/}
                            {/*        value: job,*/}
                            {/*        label: job,*/}
                            {/*        name: "mainlenguajes"*/}
                            {/*    }))]}*/}
                            {/*/>*/}
                            {/*<label htmlFor="email"> Lenguajes de programación secundarios que manejas</label>*/}
                            {/*<Select*/}
                            {/*    name="secondlenguajes"*/}
                            {/*    id="secondlenguajes"*/}
                            {/*    isMulti*/}
                            {/*    onChange={(e) => handleSelectChange(e, "secondlenguajes")}*/}
                            {/*    placeholder="Javascript, C++..."*/}
                            {/*    styles={{*/}
                            {/*        container: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            zIndex: 1004*/}
                            {/*        }),*/}
                            {/*        valueContainer: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            fontSize: '14px'*/}
                            {/*        }),*/}
                            {/*        option: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            borderBottom: '1px dotted pink',*/}
                            {/*            color: state.isSelected ? '#FFF' : palette.blue,*/}
                            {/*            padding: 10,*/}
                            {/*            fontSize: '14px'*/}
                            {/*        }),*/}
                            {/*    }}*/}
                            {/*    options={[...secondlenguajes.map(job => ({*/}
                            {/*        value: job,*/}
                            {/*        label: job,*/}
                            {/*        name: "secondlenguajes"*/}
                            {/*    }))]}*/}
                            {/*/>*/}
                            <label htmlFor="typeVacancy">Tipo de vacante que buscas *</label>
                            <Select
                                name="typeVacancy"
                                id="typeVacancy"
                                isMulti
                                onChange={(e) => handleSelectChange(e, "typeVacancy")}
                                placeholder="Senior, Junior..."
                                styles={{
                                    container: (provided, state) => ({
                                        ...provided,
                                        zIndex: 1003
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        fontSize: '14px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        borderBottom: '1px dotted pink',
                                        color: state.isSelected ? '#FFF' : palette.blue,
                                        padding: 10,
                                        fontSize: '14px'
                                    }),
                                }}
                                options={[...typeVacancy.map(job => ({
                                    value: job,
                                    label: job,
                                    name: "typeVacancy"
                                }))]}
                            />
                            <label htmlFor="typeVacancy">Institución o comunidad a la que perteneces</label>
                            <Select
                                name="typeSchool"
                                id="typeSchool"
                                isMulti
                                onChange={(e) => handleSelectChange(e, "typeSchool")}
                                placeholder="Platzi, Hola Code..."
                                styles={{
                                    container: (provided, state) => ({
                                        ...provided,
                                        zIndex: 1002
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        fontSize: '14px'
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        borderBottom: '1px dotted pink',
                                        color: state.isSelected ? '#FFF' : palette.blue,
                                        padding: 10,
                                        fontSize: '14px'
                                    }),
                                }}
                                options={[...typeSchool.map(job => ({
                                    value: job,
                                    label: job,
                                    name: "typeSchool"
                                }))]}
                            />
                            <label htmlFor="typeVacancy">Compartiremos tu información con las empresas participantes
                                para facilitar la búsqueda de empleo. LaPieza.io no se hará responsable del uso que
                                estos terceros hagan de los datos, por lo que te recomendamos no compartir datos
                                sensibles y únicamente datos de contacto.</label>
                            {/*<Select*/}
                            {/*    name="share"*/}
                            {/*    id="share"*/}
                            {/*    onChange={(e) => handleSelectChange(e, "share")}*/}
                            {/*    placeholder="Selecciona opción"*/}
                            {/*    styles={{*/}
                            {/*        container: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            zIndex: 1000*/}
                            {/*        }),*/}
                            {/*        valueContainer: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            fontSize: '14px'*/}
                            {/*        }),*/}
                            {/*        option: (provided, state) => ({*/}
                            {/*            ...provided,*/}
                            {/*            borderBottom: '1px dotted pink',*/}
                            {/*            color: state.isSelected ? '#FFF' : palette.blue,*/}
                            {/*            padding: 10,*/}
                            {/*            fontSize: '14px'*/}
                            {/*        }),*/}
                            {/*    }}*/}
                            {/*    options={[...share.map(job => ({*/}
                            {/*        value: job == 'Si' ? true : false,*/}
                            {/*        label: job,*/}
                            {/*        name: "share"*/}
                            {/*    }))]}*/}
                            {/*/>*/}
                            <button style={{marginTop: 10}}>Terminar mi registro</button>
                        </form>
                    </Paper>
                </Collapse>
            </div>
        </div>
    );
}

export default styled(Creator)`
  width: 100%;
  display: grid;
  grid-template-columns: 45% 55%;
  overflow-y: hidden;
  height: 100vh;
  .creator-left {
    width: 100%;
    background-size: cover;
    height: 100vh;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .creator-right {
    width: 100%;
    padding: 10vh 24px;
    display: block;
    justify-content: center;
    overflow-y: auto;
    img {
      width: 20%;
      margin: 0px auto;
    }
    h3 {
      font-size: 24px;
      text-align: center;
      margin: 0px;
      padding: 40px 0px;
    }
    form {
      width: 70%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      line-height: 2.4;
      a {
        color: ${palette.black};
      }
      input {
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #d3d5d8;
        padding: 10px 15px;
        &:focus {
          outline: none;
        }
      }
      label {
        font-size: 20px;
        padding: 10px 0px;
        text-align: left;
        line-height: 1.4;
        margin-top: 10px;
      }
      @media (max-width: 600px) {
        width: 90%;
      }
    }
    button {
      padding: 10px 30px;
      background-color: ${palette.blue};
      font-size: 16px;
      color: #FFF;
      font-weight: bold;
      border-radius: 4px;
      width: 100%;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
  }
  .creator-right_terminos {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    input {
      margin: 0px;
      width: fit-content;
      font-size: 16px;
    }
    a {
      margin: 0px;
      text-decoration: none;
      font-size: 16px;
      padding-left: 10px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
